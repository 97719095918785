import React from "react"
import { Container, Col, Row, Table, Image, Figure,Card} from "react-bootstrap"
import { graphql } from "gatsby"
//import { Link } from "gatsby"
import Layout from "../../components/layoutHome"
import SEO from "../../components/seo"
import { makeStyles} from '@material-ui/core/styles';
import {
    Typography,
    Grid,
    Paper,
    Button,
    Dialog, 
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
Title:{
        width:"100%",
        display:"flex",
        justifyContent:"Center",
        //margin:"30px",
        height:"30px",
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
        background: "rgba(71,  0,  0, 0.8)",
        color:"white",
        fontSize:"28px",
        alignItems:"center"
      },
      SubTitle:{
        width:"100%",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        marginBottom:"10px",
        textAlign:"justify",
        background: "rgba(71,  0,  0, 0.8)",
        color:"white",
        
      },
      imgContainer:{
        padding:"5px",
        width:"100%",
        display:"flex",
        justifyContent:"center",
        //alignItems:"center",
        height:"100%",
        maxHeight:"100%",

      },
      imgText:{
          textAlign:"justify",

      },
      img:{
          border:"black",
          borderStyle:"groove"
      },
      GridContainer:{
          margin:"10px"
      },
      EventContainer:{
        margin:"10px"
      },
      TextEvent:{
        width:"100%",
        padding:"10px",
        textAlign:"justify"
      },
      Seccion:{
          marginTop:"10px",
          marginBottom:"10px",
          paddingLeft:"10px",
          paddingRight:"10px"
      },
      espacio:{
          padding:"5px"
      },
      Principal:{
        maxHeight:"550px",
        maxWidth:"100%",
        padding:"10px",
      },
      btn:{
        width:"100%",
        height:"100%",
        color:"white",
        backgroundColor: "#ad1d21",
        borderColor: '#911619',
        fontFamily: 'Montserrat',
        '&:hover': {
          backgroundColor: '#911619',
          borderColor: '#911619',
          boxShadow: 'none',
        },
      }

  }));

const Convenios  = ({ data }) => {
  const Model = data.allCeformaPagesShow11.edges[0].node.data.page.content
  const classes = useStyles(); 
  const [open, setOpen] = React.useState(false);
    const [actualEvent, SetActualEvent] = React.useState({})

    const handleClickOpen = event => () => {
      SetActualEvent(event)
      setOpen(true);
      console.log(event)
    };

    const handleClose = () => {
        setOpen(false);
      };

  //console.log(Model)
  return (
    <Layout>
      <SEO title="Catalogos" />
      <Container fuid>
        <Typography className={classes.Title}  >
            Catalogo
        </Typography>

    <Grid container>
    <Typography className={classes.Title}  >Temas laborales</Typography>
    {
        Model.TemasLaborales.length > 0 ? (
          <Grid container className={classes.full}>
          {Model.TemasLaborales.map((Article , index) => {
          return (
            <Grid iteam xs={12} sm={6} md={4} lg={4} xl={4}
            className={classes.espacio}
            >
                <Paper className={classes.imgContainer}>
                <Card>
                <Card.Img variant="top" src={"https://picsum.photos/500/200"} />
                    <Card.Body>
                    <Card.Title>{Article.title} [{Article.hous}hrs]</Card.Title>
                    <Card.Text>
                    {Article.objetive}
                    </Card.Text>
                    </Card.Body>
                    <Card.Footer>
                        <Button className={classes.btn} onClick={handleClickOpen(Article.modules)}>
                           Detalles
                        </Button>
                    </Card.Footer>
                </Card>
                </Paper> 
              </Grid>  
                  );
                })} 
        </Grid>

          ) : (
            <Paper className={classes.Table}>
          
          </Paper>
     )}



    </Grid>


    <Grid container>
    <Typography className={classes.Title}  >Temas Recursos Humanos</Typography>

    {
        Model.TemasRH.length > 0 ? (
          <Grid container className={classes.full}>
          {Model.TemasRH.map((Article , index) => {
          return (
            <Grid iteam xs={12} sm={6} md={4} lg={4} xl={4}
            className={classes.espacio}
            >
                <Paper className={classes.imgContainer}>
                <Card>
                <Card.Img variant="top" src={"https://picsum.photos/500/200"} />
                    <Card.Body>
                    <Card.Title>{Article.title} [{Article.hous}hrs]</Card.Title>
                    <Card.Text>
                    {Article.objetive}
                    </Card.Text>
                    </Card.Body>
                    <Card.Footer>
                        <Button className={classes.btn} onClick={handleClickOpen(Article.modules)} >
                           Detalles
                        </Button>
                    </Card.Footer>
                </Card>
                </Paper> 
              </Grid>  
                  );
                })} 
        </Grid>

          ) : (
            <Paper className={classes.Table}>
          
          </Paper>
     )}


    </Grid>

    <Grid container>
    <Typography className={classes.Title} >Temas Sindicales</Typography>
    {
        Model.TemasSindicales.length > 0 ? (
          <Grid container className={classes.full}>
          {Model.TemasSindicales.map((Article , index) => {
          return (
            <Grid iteam xs={12} sm={6} md={4} lg={4} xl={4}
            className={classes.espacio}
            >
                <Paper className={classes.imgContainer}>
                <Card>
                <Card.Img variant="top" 
                //src={"https://picsum.photos/500/200"}
                src={Article.img}
                />
                    <Card.Body>
                    <Card.Title>{Article.title} [{Article.hous}hrs]</Card.Title>
                    <Card.Text>
                    {Article.objetive}
                    </Card.Text>
                    </Card.Body>
                    <Card.Footer>
                        <Button className={classes.btn} onClick={handleClickOpen(Article.modules)}>
                           Detalles
                        </Button>
                    </Card.Footer>
                </Card>
                </Paper> 
              </Grid>  
                  );
                })} 
        </Grid>

          ) : (
            <Paper className={classes.Table}>
          
          </Paper>
     )}


    </Grid>

    <Grid container>
    <Typography className={classes.Title} >COSTOS DE LOS CURSOS</Typography>
    
    {
        Model.Costos.length > 0 ? (
          <Grid container 
          className={classes.full}
          >
        <Grid container className={classes.quienes} >
            <Paper className={classes.EventContainer}> 
            <ul>
          {Model.Costos.map((Article , index) => {
          return (
                  
                       <li>
                           {Article.Punto}
                       </li> 
                     
                  );
                })}
                 </ul> 
             </Paper>      
            </Grid>
        </Grid>
          ) : (
            <Paper className={classes.Table}>
          
          </Paper>
     )}




    </Grid>


      </Container>
    
    
      <Dialog
          open={open}
          onClose={handleClose}
          scroll='paper'
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title" className={classes.title} >Modulos</DialogTitle>
          <DialogContent dividers={true}>
            <DialogContentText
              id="scroll-dialog-description"
              
              tabIndex={-1}
              className={classes.SinEventos}
            >
    {
        actualEvent.length > 0 ? (
          <Grid container 
          className={classes.full}
          >
        <Grid container className={classes.quienes} >
            <Paper className={classes.EventContainer}> 
            <ul>
          {actualEvent.map((Article , index) => {
          return (
                <li>
                    {Article.modulo}
                </li>
                     
                  );
                })}
                 </ul> 
             </Paper>      
            </Grid>
        </Grid>
          ) : (
            <Paper className={classes.Table}>
          
          </Paper>
     )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
    
    
    
    
    
    
    </Layout>
  )
}

export default  Convenios 

export const query = graphql`
  query catalogo {
    allCeformaPagesShow11 {
        edges {
          node {
            data {
              page {
                content {
                  Costos {
                    Punto
                  }
                  TemasLaborales {
                    title
                    objetive
                    hous
                    img
                    modules {
                      modulo
                    }
                  }
                  TemasRH {
                    title
                    objetive
                    img
                    modules {
                      modulo
                    }
                    hous
                  }
                  TemasSindicales {
                    title
                    objetive
                    img
                    modules {
                      modulo
                    }
                    hous
                  }
                  welcomeText
                  title
                }
              }
            }
          }
        }
      }
  }
`
